<template>
  <div class="fiat-section">
    <div class="input-container">
      <input
        type="number"
        :value="giveAmount"
        @input="$emit('update:give-amount', parseFloat($event.target.value)); changeTakeAmount($event.target.value);"
      />
      <div class="desc">{{ giveCurrency.title }}</div>
    </div>
    <div class="radio-buttons">
      <div class="desc">{{ $t(`Choose a currency:`) }}</div>
      <div
        class="list"
        v-if="currencies"
      >
        <div
          class="item"
          v-for="item in currencies"
          :key="item.title"
        >
          <label>
            <input
              type="radio"
              :checked="giveCurrency.title === item.title"
              :value="item.title"
              @change="chooseCurrency(item)"
            />
            <div class="desc">{{ item.title }}</div>
          </label>
        </div>
      </div>
    </div>
    <div
      v-if="$parent.fiatToCrypto"
      class="radio-list payment-method-list"
    >
      <div class="desc">{{ $t(`Payment method:`) }}</div>

      <div
        v-if="paymentMethods && paymentMethods.length > 0"
        class="list"
      >
        <div
          v-for="{ code, image_full, title } in paymentMethods"
          :key="title"
          class="item"
        >
          <label>
            <input
              type="radio"
              :checked="paymentMethod === code"
              @change="$emit('updatePaymentMethod', code)"
              name="paymentMethod"
              :value="code"
            />
            <div class="text">
              <img
                v-if="image_full"
                :src="image_full"
                class="img"
              />
              <div class="desc">{{ $t(title) }}</div>
            </div>
          </label>
        </div>
      </div>

      <div
        v-else
        class="list"
      >
        <div>
          <label>
            <!-- <input type="radio" v-model="paymentMethod" @change="$emit('updatePaymentMethod', code)" name="paymentMethod" :value="code"/> -->
            <div class="text">
              <!-- <img v-if="image_full" :src="image_full" class="img"/> -->
              <div class="desc"> {{ this.$parent.paymentMethodError }}
              </div>
            </div>
          </label>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: 'FiatSection',
  props: {
    giveCurrency: {
      type: Object
    },
    giveAmount: {
      type: Number
    },
    defaultTakeCurrency: {
      type: Function
    },
    currencies: {
      type: Object
    },
    changeTakeAmount: {
      type: Function
    },
    toggleFiatToCrypto: {
      type: Function
    },
    paymentMethod: {
      type: String,
      default: ''
    },
    paymentMethods: {
      type: Array,
      default: () => []
    }
  },
  data: function () {
    return {
    }
  },
  watch: {
  },
  methods: {
    chooseCurrency(event) {
      this.$emit('update:give-currency', event);
      this.defaultTakeCurrency();
    }
  },
  computed: {
    // apiUrl () {
    //   return process.env.VUE_APP_URL;
    // }
  },
  mounted() {
  }
}
</script>
