<template>
  <div class="modal sign-modal">
    <div class="overlay"  @click="$parent.closeSignInModal"></div>
    <div class="wrapper">
      <img class="close" @click="$parent.closeSignInModal" src="./../images/close.svg"/>
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <div class="form-fields">
              <div class="form-fields-wrapper">
                <div class="title">{{ $t(`Welcome back!`) }}</div>
                <label>
                  <div class="desc">{{ $t(`E-mail address:`) }}</div>
                  <input type="email" v-model="email"/>
                </label>
                <label>
                  <div class="desc">{{ $t(`Password:`) }}</div>
                  <input type="password" v-model="pass"/>
                </label>
                <div class="link-container">
                  <!-- <label class="chekbox-label">
                    <div class="chekbox-label-wrapper">
                      <input type="checkbox" name="remember" v-model="remember"/>
                      <div class="checkbox"></div>
                      <span class="desc">{{ $t(`Remember me`) }}</span>
                     </div>
                  </label> -->
                  <div class="desc link" @click="$parent.openRecover">{{ $t(`Forgot password?`) }}</div>
                </div>
                <button class="button" @click="submitLogin">
                  <span>{{ $t(`Login`) }}</span>
                </button>
                <div class="desc link" @click="$parent.openRegister">{{ $t(`Sign Up!`) }}</div>
                <div v-if="$parent.error" class="desc red">{{$parent.error}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SignInModal',
  props: [],
  components: {
  },
  data: function() {
    return {
      email: '',
      pass: ''
      // remember: false
    }
  },
  // watch: {
  //   rememberMe(value) {
  //     localStorage.setItem('remember', JSON.stringify(value));
  //   }
  // },
  // mounted() {
  //   const remember = localStorage.getItem('remember');
  //   if (remember) {
  //     this.remember = JSON.parse(remember);
  //   }
  // },
  computed: {
    
  },
  methods: {
    submitLogin() {
      let loginData = {
        "username": this.email,
        "password": this.pass
      }

      this.$emit('login', loginData)
    }
  }
}
</script>