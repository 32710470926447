import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: 'en', // set the initial locale
    fallbackLocale: 'en', // set the fallback locale
    messages: {},
    silentFallbackWarn: true, // disable warning message when fallback is used
})

export default i18n