<template>
  <main class="main page-inside">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="title">{{ $t(`Buy Cryptocurrency`) }}</div>
          <div class="content">
            <div class="card-list">
              <div class="card-list__item give">
                <div class="item__header">
                  <div class="title small">{{ $t(`Give`) }}</div>
                  <div
                    v-if="$parent.wallets && $parent.wallets.length"
                    class="exchange"
                  >
                    <ExchangeButton @click="toggleFiatToCrypto" />
                  </div>
                </div>

                <FiatSection
                  v-if="fiatToCrypto"
                  v-bind:give-amount.sync="giveAmount"
                  :change-take-amount="changeTakeAmount"
                  :default-take-currency="defaultTakeCurrency"
                  v-bind:give-currency.sync="giveCurrency"
                  v-bind:payment-method.sync="paymentMethod"
                  :currencies="filteredCurrencies"
                  :toggle-fiat-to-crypto="toggleFiatToCrypto"
                  @updatePaymentMethod="updatePaymentMethod"
                  :payment-methods="paymentMethods"
                />
                <CryptoSection
                  v-else
                  v-bind:take-currency.sync="takeCurrency"
                  :give-currency="giveCurrency"
                  v-bind:take-amount.sync="takeAmount"
                  :rates="rates"
                  :filtered-rates="filteredRates"
                  :toggle-fiat-to-crypto="toggleFiatToCrypto"
                  :change-give-amount="changeGiveAmount"
                  v-bind:payment-method.sync="paymentMethod"
                  :payment-methods="paymentMethods"
                />

              </div>
              <div class="card-list__item take">
                <div class="top">
                  <div class="title small">{{ $t(`Take`) }}</div>
                  <div class="desc">1 {{ takeCurrency.crypto_currency }} = {{ takeCurrency.price }} {{
                    giveCurrency.title }}
                    <ExchangeButton
                      v-if="$parent.wallets && $parent.wallets.length"
                      @click="toggleFiatToCrypto"
                    />
                  </div>
                </div>
                <CryptoSection
                  v-if="fiatToCrypto"
                  v-bind:take-currency.sync="takeCurrency"
                  :give-currency="giveCurrency"
                  v-bind:take-amount.sync="takeAmount"
                  :rates="rates"
                  :filtered-rates="filteredRates"
                  :change-give-amount="changeGiveAmount"
                  v-bind:payment-method.sync="paymentMethod"
                  :payment-methods="paymentMethods"
                />
                <FiatSection
                  v-else
                  v-bind:give-amount.sync="giveAmount"
                  :change-take-amount="changeTakeAmount"
                  :default-take-currency="defaultTakeCurrency"
                  v-bind:give-currency.sync="giveCurrency"
                  v-bind:payment-method.sync="paymentMethod"
                  :currencies="filteredCurrencies"
                  :payment-methods="paymentMethods"
                />
              </div>
              <div class="card-list__item transactions">
                <div class="title small">{{ $t(`Transactions`) }}</div>
                <div
                  class="desc"
                  v-if="!$parent.orderHistory.length"
                >{{ $t(`No orders yet`) }}</div>
                <div
                  class="list"
                  v-if="$parent.orderHistory.length"
                >
                  <div
                    class="item"
                    v-for="(item, index) in $parent.orderHistory"
                    :key="index"
                  >
                    <div class="desc align-left">#{{ item.id }}</div>
                    <div class="desc align-left"></div>
                    <div class="desc align-left">{{ item.created_at | moment('DD MMMM YYYY, HH:mm') }}</div>
                    <div class="desc align-left">{{ item.dest_amount }} {{ item.dest_asset.toUpperCase() }}</div>
                    <div class="desc align-left">{{ item.status }}</div>
                    <div class="desc align-left">- {{ item.source_asset }} {{ item.amount }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-list__item buy-item">
              <div class="desc">{{ $t(`Buy bitcoin with profitable exchange rate`) }}</div>
              <button
                :class="['button', { 'disabled': !this.giveAmount || !this.takeAmount }]"
                v-if="$parent.isAuth"
                @click="submit"
              >{{ $t(`Buy`) }}</button>
              <button
                class="button"
                v-if="!$parent.isAuth"
                @click="$parent.openSignInModal"
              >{{ $t(`Buy`) }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { debounce } from 'lodash'
import ExchangeButton from "@/components/ExchangeButton.vue";
import FiatSection from "@/components/FiatSection.vue";
import CryptoSection from "@/components/CryptoSection.vue";
import router from "@/router";
export default {
  name: 'BuyCrypto',
  props: ['currencies', 'crypto', 'rates'],
  components: {
    CryptoSection,
    FiatSection,
    ExchangeButton
  },
  created() {
    this.debouncedFetchAvailablePaymentMethods = debounce(this.fetchAvailablePaymentMethods, 300);
  },
  data: function () {
    return {
      debouncedFetchAvailablePaymentMethods: () => {},
      giveCurrency: {},
      takeCurrency: {},
      giveAmount: 100,
      takeAmount: 0,
      paymentMethod: '',
      fiatToCrypto: true,

      paymentMethodError: '',

      availablePaymentMethods: [],
      loadingPaymentMethods: false,
    }
  },
  watch: {
    '$parent.isAuth': {
      immediate: true,
      handler() {
        if (this.$parent.isAuth) {
          this.debouncedFetchAvailablePaymentMethods();
        }
      },
    },
    currencies: {
      immediate: true,
      handler() {
        if (Object.values(this.filteredCurrencies)[0]) {
          this.giveCurrency = Object.values(this.filteredCurrencies)[0];
        }
      },
    },
    giveCurrency: {
      immediate: true,
      handler() {
        if (this.fiatToCrypto && this.giveCurrency?.code) {
          this.debouncedFetchAvailablePaymentMethods();
        }
      },
    },
    takeCurrency: {
      immediate: true,
      handler() {
        this.changeTakeAmount(this.giveAmount);
      },
    },
    rates: {
      immediate: true,
      handler(newValue) {
        if (newValue.length && this.giveCurrency?.code) {
          // Find the rate matching the selected currency
          const matchingRate = newValue.find(rate =>
            rate.currency === this.giveCurrency.code
          );
          if (matchingRate) {
            this.takeCurrency = matchingRate;
          }
        }
      }
    }
  },
  computed: {
    filteredRates() {
      let rates = this.$parent.rates.filter(item => item.currency === this.giveCurrency?.code);

      if (!this.fiatToCrypto) {
        const parent = this.$parent;
        const wallets = parent.wallets && parent.wallets.map((currency) => currency.toUpperCase());
        rates = rates.filter(item => wallets && wallets.includes(item.crypto_currency.toUpperCase()))
      }

      return rates;
    },
    filteredCurrencies() {
      if (this.fiatToCrypto) {
        return Object.fromEntries(Object.entries(this.$parent.currencies).filter(([, data]) => data.sale));
      } else {
        return Object.fromEntries(Object.entries(this.$parent.currencies).filter(([, data]) => data.buy));
      }
    },
    paymentMethods() {
      return this.availablePaymentMethods;
    }
  },
  methods: {
    fetchAvailablePaymentMethods() {
      this.loadingPaymentMethods = true;
      this.$http.post(`${process.env.VUE_APP_API}available-methods`, {
        currency: this.giveCurrency.code,
      })
        .then(response => {
          if (response.data.status === 'OK') {
            this.availablePaymentMethods = response.data.payload;
            this.paymentMethod = this.availablePaymentMethods[0]?.code;

            if (response.data.payload.length == 0) {
              this.paymentMethodError = "No available payment methods for this currency"
            }

          } else if (response.data.status === 'WARNING') {
            this.availablePaymentMethods = [];
            // this.$toast(response.data.message, { type: 'warning' });
            this.paymentMethodError = response.data.message;
          } else {
            // Handle other error statuses
            this.availablePaymentMethods = [];
            this.paymentMethodError = 'Error fetching payment methods'
          }
        })
        .catch((error) => {
          this.availablePaymentMethods = [];
          if (error.request.status == 401) {
            this.paymentMethodError = this.$t("Please login first to see payment methods");
          }
          else {
            this.paymentMethodError = 'Error fetching payment methods'
          }
        })
        .finally(() => {
          this.loadingPaymentMethods = false;
        });
    },
    updatePaymentMethod(method) {
      this.paymentMethod = method;
    },
    changeTakeAmount(newValue) {
      if (this.takeCurrency.price) {
        this.takeAmount = newValue / this.takeCurrency.price;
      } else {
        this.takeAmount = 0;
      }
    },
    changeGiveAmount(newValue) {
      if (this.takeCurrency.price) {
        this.giveAmount = this.takeCurrency.price * newValue;
      } else {
        this.giveAmount = 0;
      }
    },
    defaultTakeCurrency() {
      if (this.filteredRates && this.filteredRates.length) {
        this.takeCurrency = this.filteredRates[0];
      }
    },
    async submit() {
      const fiatAmount = this.giveAmount;

      if (!this.$parent.isEmailVerified()) {
        router.push('/email/verify/notice');
        return;
      }

      if (this.$parent.topOrderLimit && fiatAmount > this.$parent.topOrderLimit) {
        this.$toast(`Max order price is ${this.$parent.topOrderLimit} EUR.`, { type: 'error' });
        return;
      }

      if (this.fiatToCrypto && this.$parent.minOrderPriceFiatToCrypto && fiatAmount < this.$parent.minOrderPriceFiatToCrypto) {
        this.$toast(`Min order price is ${this.$parent.minOrderPriceFiatToCrypto} EUR.`, { type: 'error' });
        return;
      } else if (!this.fiatToCrypto && this.$parent.minOrderPriceCryptoToFiat && fiatAmount < this.$parent.minOrderPriceCryptoToFiat) {
        this.$toast(`Min order price is ${this.$parent.minOrderPriceCryptoToFiat} EUR.`, { type: 'error' });
        return;
      }

      if (this.fiatToCrypto && !await this.validateAmount()) {
        return;
      }

      this.$emit('setCheckoutData', this.giveAmount, this.takeAmount, this.takeCurrency, this.giveCurrency, this.paymentMethod, this.fiatToCrypto)
      this.$router.push({ path: '/order-details' });
    },

    async validateAmount() {
      try {
        const response = await this.$http.post(`${process.env.VUE_APP_API}payment-method/validate`, {
          currency: this.giveCurrency.code,
          payment_method_code: this.paymentMethod?.code || this.paymentMethod,
          amount: this.giveAmount,
        });

        if (response.status === 200 && response.data.status === 'OK') {
          return true;
        } else {
          this.$toast(response.data.message, { type: 'warning' });
          return false;
        }
      } catch (error) {
        console.error('Error validating payment method:', error);
        this.$toast.error(error.response?.data?.message || 'An error occurred. Please try again later.');
        if (error.response?.data?.redirect) {
          this.$router.push('/profile?scrollToAdditionalData');
        }
        return false;
      }
    },
    toggleFiatToCrypto() {
      this.fiatToCrypto = !this.fiatToCrypto;
      this.paymentMethod = this.fiatToCrypto ? this.availablePaymentMethods[0]?.code : 'crypto';
      let currencies = Object.entries(this.filteredCurrencies);

      if (!currencies.some(([, data]) => data.title === this.giveCurrency.title)) {
        this.giveCurrency = currencies[0][1];
      }

      // Update takeCurrency based on wallets and rates
      if (!this.fiatToCrypto) {
        const wallets = this.$parent.wallets && this.$parent.wallets.map(w => w.toUpperCase());
        const availableRates = this.rates.filter(rate => wallets && wallets.includes(rate.crypto_currency.toUpperCase()));

        if (availableRates.length > 0) {
          // Find a matching rate based on the current giveCurrency if possible
          let matchingRate = availableRates.find(rate => rate.currency === this.giveCurrency.code);
          this.takeCurrency = matchingRate || availableRates[0];
        } else {
          this.takeCurrency = {};
          // console.error("No rates available for the currencies in your wallet.");
        }
      } else {
        this.defaultTakeCurrency();
        this.debouncedFetchAvailablePaymentMethods();
      }
    }
  },
  beforeUnmount() {
    this.debouncedFetchAvailablePaymentMethods.cancel();
  },
  mounted: function () {
    this.debouncedFetchAvailablePaymentMethods = debounce(this.fetchAvailablePaymentMethods, 300);
    // Preserve the selected currency from parent
    if (this.$parent.giveCurrency) {
      this.giveCurrency = this.$parent.giveCurrency;
    } else {
      // Only use default if no currency was previously selected
      this.giveCurrency = Object.values(this.filteredCurrencies)[0];
    }

    if (this.fiatToCrypto && this.giveCurrency?.code) {
      this.debouncedFetchAvailablePaymentMethods();
    }

    if (this.$parent.giveAmount) {
      this.giveAmount = this.$parent.giveAmount;
      this.takeAmount = this.$parent.takeAmount;



      // Find the correct rate for the preserved currency
      if (this.$parent.takeCurrency) {
        const matchingRate = this.rates.find(rate =>
          rate.currency === this.giveCurrency.code &&
          rate.crypto_currency === this.$parent.takeCurrency.crypto_currency
        );
        this.takeCurrency = matchingRate || this.$parent.takeCurrency;
      }
    }
  }

}
</script>