<template>
  <div class="verification-notice">
    <h1>{{ $t(`Email Verification Required`) }}</h1>
    <p class="email-desc">{{ $t(`Please check your email for a verification link. You must verify your email before you can
      continue.`) }}</p>
    <button class="button email-button" @click="resendVerificationEmail">{{ $t(`Resend Verification Email`) }}</button>
    <button class="button email-button" @click="$parent.logout">{{ $t(`Logout`) }}</button>
  </div>
</template>
  
<script>
export default {
  name: 'EmailVerificationNotice',
  methods: {
    resendVerificationEmail() {
      this.$http.post(process.env.VUE_APP_API + 'email/verify/resend')
        .then((res) => {
          // handle success
          this.$toast.success(res.data.message)
          this.successMessage = 'Verification email sent!';
        })
        .catch(() => {
          // handle error
          this.$toast.error('Failed to send verification email')
          this.errorMessage = 'Failed to send verification email.';
        });
    }
  }
}
</script>

<style scoped>
.verification-notice {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
  min-height: 80vh;
  color: #000;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  padding: 20px;
}

.email-button {
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  max-width: 300px;
}

.email-desc {
  line-height: 20px;
  text-align: center;
}
</style>
  