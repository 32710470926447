<template>
  <div id="webchat" />
</template>

<script>
export default {
  mounted() {
    const script = document.createElement('script')
    script.src = 'https://cdn.botpress.cloud/webchat/v1/inject.js'
    script.async = true
    document.body.appendChild(script)

    script.onload = () => {
      window.botpressWebChat.init({
        "composerPlaceholder": "Live Chat with Exchange Daxchain",
        "botConversationDescription": "Live Chat with Exchange Daxchain",
        "botId": "ce284290-82b2-4417-aa66-1694b8a2a8b2",
        "hostUrl": "https://cdn.botpress.cloud/webchat/v1",
        "messagingUrl": "https://messaging.botpress.cloud",
        "clientId": "ce284290-82b2-4417-aa66-1694b8a2a8b2",
        "webhookId": "46ec0394-6d38-456d-b84f-48e00aec2ee4",
        "lazySocket": true,
        "themeName": "prism",
        "botName": "Live chat",
        "avatarUrl": "https://cdn-icons-png.freepik.com/512/4712/4712060.png",
        "frontendVersion": "v1",
        "useSessionStorage": true,
        "enableConversationDeletion": true,
        "theme": "prism",
        "themeColor": "#2563eb"
      });
    }
  }
}
</script>

<style scoped>

</style>