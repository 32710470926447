<template>
	<footer class="footer">
		<div class="wrapper">
			<ul class="nav">
				<template v-for="item in $parent.footerNav">
					<li
						class="nav__item"
						:key="item.title"
					>
						<router-link
							class="desc"
							:to="item.path"
						>
							{{ item.title }}
						</router-link>
					</li>
				</template>
				<template v-for="item in $parent.textPageList">
					<li
						class="nav__item"
						:key="item.id"
					>
						<a
							class="desc"
							@click="$parent.goToPage(item)"
						>
							{{ item.title }}
						</a>
					</li>
				</template>
			</ul>
			<div
				v-if="hasCountryObject"
				class="nav__item allowed-countries"
			>
				<a
					@click="$parent.goToAllowedCountries"
					class="desc"
				>{{ $t(`Allowed countries`) }}</a>
			</div>
		</div>
		<div class="wrapper wrapper_social">
			<div
				class="social-links"
				v-if="$parent.socialLinks.length > 0"
			>
				<div
					v-for="link in $parent.socialLinks"
					:key="link.img"
					class="social_links__link"
				>
					<a :href="link.link">
						<img
							:src="link.img"
							:alt="link.link"
						/>
					</a>
				</div>
			</div>
		</div>
		<div class="wrapper">
			<div
				v-if="$parent.legalInfo"
				class="desc"
			>{{ $parent.legalInfo }}</div>
			<div class="list">
				<!-- <div class="item" v-if="$parent.phone">
					<div class="desc">{{ $t(`Phone:`) }}</div>
					<a :href="'tel:' + $parent.phone" class="desc">{{$parent.phone}}</a>
				</div>
				<div class="item" v-if="$parent.email">
					<div class="desc">{{ $t(`E-mail:`) }}</div>
					<a :href="'mailto:' + $parent.email" class="desc">{{$parent.email}}</a>
				</div> -->
			</div>
		</div>
		<div class="wrapper">
			<div class="desc">© {{ new Date().getFullYear() }} All Rights Reserved</div>

			<ul
				class="payment-methods-list"
				v-if="allFooterImages.length"
			>
				<li
					v-for="image in allFooterImages"
					:key="image"
					class="image-wrapper"
				>
					<img
						:src="image"
						class="support-icon img"
						alt="Payment method"
					/>
				</li>
			</ul>
		</div>
	</footer>
</template>

<script>
export default {
	name: 'Footer',
	props: {
		footerImages: {
			type: Array,
			required: false,
			default: () => [],
		},
	},
	computed: {
		allFooterImages() {
			return this.footerImages;
		},
		hasCountryObject() {
			return this.$parent.textPageList.some(obj => obj.hasOwnProperty("is_countries") && obj.is_countries === 1);
		},
	},
}
</script>

<style lang="scss" scoped>
.footer .payment-methods-list {
	display: flex;
	gap: 10px;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;

	.image-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 32px;
		max-width: 70px;
	}

	.support-icon.img {
		max-width: 100%;
		max-height: 100%;
		height: 100%;
		object-fit: contain;
		display: block;
	}
}
</style>