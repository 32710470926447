<template>
  <main class="main page-inside">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="content">
            <div class="card-list">
              <template v-if="!submitSuccess">
              <ValidationObserver slim v-slot="{ handleSubmit, invalid, dirty, submitting }">
                <form class="card-list__item order-details" @submit.prevent="handleSubmit(submit)">
                <div class="title small">{{ $t(`Order details`) }}</div>
<!--                <div class="desc">{{ $t(`Choose payment method:`) }}</div>-->
<!--                <div class="radio-list">-->
<!--                  <div class="list">-->
<!--                    <div class="item"></div>-->
<!--                    <div class="item">-->
<!--                      <label>-->
<!--&lt;!&ndash;                        <input type="radio" v-model="paymentMethod" name="paymentMethod" value="card"/>&ndash;&gt;-->
<!--&lt;!&ndash;                        <div class="text">&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                          <svg class="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.667 17.841">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                            <g id="Layer_1" transform="translate(-2.13 -5.896)">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                              <path id="Контур_12558" data-name="Контур 12558" d="M6.467,23.737h16A4.338,4.338,0,0,0,26.8,19.4V10.229A4.338,4.338,0,0,0,22.464,5.9h-16a4.338,4.338,0,0,0-4.333,4.333v1.544c0,.008,0,.014,0,.021s0,.014,0,.021V19.4a4.338,4.338,0,0,0,4.333,4.333Zm16-1.766h-16A2.57,2.57,0,0,1,3.9,19.4V12.678H25.031V19.4A2.57,2.57,0,0,1,22.464,21.972Zm-16-14.31h16a2.57,2.57,0,0,1,2.567,2.567v.683H3.9v-.683A2.57,2.57,0,0,1,6.467,7.662Z" transform="translate(0 0)" fill="#7f8798"/>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                              <path id="Контур_12559" data-name="Контур 12559" d="M10.865,20.469H6.737a.883.883,0,1,0,0,1.766h4.128a.883.883,0,0,0,0-1.766Z" transform="translate(-0.436 -1.707)" fill="#7f8798"/>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                            </g>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                          </svg>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                          <svg class="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.667 17.841">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                            <g id="Layer_1" transform="translate(-2.13 -5.896)">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                              <path id="icon" d="M6.467,23.737h16A4.338,4.338,0,0,0,26.8,19.4V10.229A4.338,4.338,0,0,0,22.464,5.9h-16a4.338,4.338,0,0,0-4.333,4.333v1.544c0,.008,0,.014,0,.021s0,.014,0,.021V19.4a4.338,4.338,0,0,0,4.333,4.333Zm16-1.766h-16A2.57,2.57,0,0,1,3.9,19.4V12.678H25.031V19.4A2.57,2.57,0,0,1,22.464,21.972Zm-16-14.31h16a2.57,2.57,0,0,1,2.567,2.567v.683H3.9v-.683A2.57,2.57,0,0,1,6.467,7.662Z" transform="translate(0 0)" fill="#F7931A"/>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                            </g>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                          </svg>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                          <svg class="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.667 17.841">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                            <g id="Layer_1" transform="translate(-2.13 -5.896)">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                              <path id="icon" d="M6.467,23.737h16A4.338,4.338,0,0,0,26.8,19.4V10.229A4.338,4.338,0,0,0,22.464,5.9h-16a4.338,4.338,0,0,0-4.333,4.333v1.544c0,.008,0,.014,0,.021s0,.014,0,.021V19.4a4.338,4.338,0,0,0,4.333,4.333Zm16-1.766h-16A2.57,2.57,0,0,1,3.9,19.4V12.678H25.031V19.4A2.57,2.57,0,0,1,22.464,21.972Zm-16-14.31h16a2.57,2.57,0,0,1,2.567,2.567v.683H3.9v-.683A2.57,2.57,0,0,1,6.467,7.662Z" transform="translate(0 0)" fill="#F7931A"/>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                            </g>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                          </svg>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                          <svg class="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                            <path d="M12 0C5.371 0 0 5.371 0 12s5.371 12 12 12 12-5.371 12-12S18.629 0 12 0zm0 22c-5.072 0-10-4.928-10-10s4.928-10 10-10 10 4.928 10 10-4.928 10-10 10zm2.173-6.529h-3.189V6.804a.637.637 0 0 0-.808-.645l-.087.021-1.186.334v8.768h-2.77a.637.637 0 0 0-.636.637l.021.087 1.959 5.548c.128.36.51.588.925.588h3.162a.637.637 0 0 0 .636-.637l-.021-.087-1.96-5.548a.654.654 0 0 0-.936-.331.638.638 0 0 0-.331.937l1.104 3.13h-2.248V15.47h2.255a.637.637 0 0 0 .636-.637l-.022-.086-1.687-4.789a.645.645 0 0 0-.811-.401c-.345.109-.542.47-.433.814l1.712 4.854h3.14c.458 0 .814-.443.786-.899l-.997-2.824a.634.634 0 0 0-.635-.414z" fill="#F7931A"/>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                          </svg>&ndash;&gt;&ndash;&gt;-->

<!--&lt;!&ndash;                          <svg class="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.667 17.841">&ndash;&gt;-->
<!--&lt;!&ndash;                            <path id="icon" d="M12 0C5.371 0 0 5.371 0 12s5.371 12 12 12 12-5.371 12-12S18.629 0 12 0zm0 22c-5.072 0-10-4.928-10-10s4.928-10 10-10 10 4.928 10 10-4.928 10-10 10zm2.173-6.529h-3.189V6.804a.637.637 0 0 0-.808-.645l-.087.021-1.186.334v8.768h-2.77a.637.637 0 0 0-.636.637l.021.087 1.959 5.548c.128.36.51.588.925.588h3.162a.637.637 0 0 0 .636-.637l-.021-.087-1.96-5.548a.654.654 0 0 0-.936-.331.638.638 0 0 0-.331.937l1.104 3.13h-2.248V15.47h2.255a.637.637 0 0 0 .636-.637l-.022-.086-1.687-4.789a.645.645 0 0 0-.811-.401c-.345.109-.542.47-.433.814l1.712 4.854h3.14c.458 0 .814-.443.786-.899l-.997-2.824a.634.634 0 0 0-.635-.414" fill="#F7931A" />&ndash;&gt;-->
<!--&lt;!&ndash;                          </svg>&ndash;&gt;-->


<!--&lt;!&ndash;                          <div class="desc">{{ $t(`Visa/Mastercard`) }}</div>&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--                      </label>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
                  <ValidationProvider slim mode="eager" name="Name" rules="required" v-slot="{ errors }">
                    <div class="input-container">
                      <div class="desc">{{ $t(`Name:`) }}</div>
                      <input type="text" required v-model="beneficiary_name"/>
                      <div v-if="errors && errors.length > 0" class="error">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>

                  <ValidationProvider slim mode="eager" name="Surname" rules="required" v-slot="{ errors }">
                    <div class="input-container">
                      <div class="desc">{{ $t(`Surname:`) }}</div>
                      <input type="text" required v-model="beneficiary_surname"/>
                      <div v-if="errors && errors.length > 0" class="error">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>


                  <ValidationProvider slim mode="eager" name="Iban" rules="required|iban" v-slot="{ errors }">
                    <div class="input-container">
                      <div class="desc">{{ $t(`IBAN:`) }}</div>
                      <input type="text" required v-model="iban"/>
                      <div v-if="errors && errors.length > 0" class="error">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>

                  <ValidationProvider slim mode="eager" name="Bic" rules="required|bic" v-slot="{ errors }">
                    <div class="input-container">
                      <div class="desc">{{ $t(`BIC / SWIFT:`) }}</div>
                      <input type="text" required v-model="bic"/>
                      <div v-if="errors && errors.length > 0" class="error">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>

                  <ValidationProvider slim mode="eager" name="Bank name" rules="required" v-slot="{ errors }">
                    <div class="input-container">
                      <div class="desc">{{ $t(`Bank name:`) }}</div>
                      <input type="text" required v-model="bank_name"/>
                      <div v-if="errors && errors.length > 0" class="error">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>

                <div class="wallet">
                  Wallet: <span class="wallet__address">{{ wallet }}</span>
                </div>
                <div class="total">
                  <div class="desc">{{ $t(`Total amount to pay:`) }}</div>
                  <div class="total-wrapper">
                    <div class="item">
                      <div class="text">
                        <div class="desc">{{ $t(`Date of order`) }}</div>
                        <div class="desc">{{ date  }}</div>
                      </div>
                      <div class="title" :class="{'crypto': isCrypto}">{{ leftAmount }}</div>
                    </div>
                    <div class="item">
                      <div class="text">
                        <div class="desc">{{ $t(`Exchange rate`) }}</div>
                        <div class="desc">{{ isCrypto ? order.source_asset : order.dest_asset }} = {{parseFloat(order.exchange_rate).toFixed(2)}} {{ fiatCurrency }}</div>
                      </div>
                      <div class="title" :class="{'crypto': !isCrypto}">{{ rightAmount }}</div>
                    </div>
                  </div>
                </div>
                <div class="total-bottom">
                  <button type="submit" :disabled="(dirty && invalid) || submitting" class="button">{{ $t(`Continue`) }}</button>
                </div>
                </form>
              </ValidationObserver>
              <div class="card-list__item-container">
                <div class="card-list__item your-card">
                  <div class="title small">{{ $t(`Your Card`) }}</div>
                  <div class="row">
                    <div class="title">{{ leftAmount }}</div>
                  </div>
                  <div class="desc">{{ rightAmount }}</div>
                  <div class="table">
                    <div class="row">
                      <div class="desc">{{ $t(`Exchange rate`) }}</div>
                      <div class="desc">1 {{ isCrypto ? order.source_asset : order.dest_asset }} = {{parseFloat(order.exchange_rate).toFixed(2)}} {{ fiatCurrency }}</div>
                    </div>
                    <div class="row">
                      <div class="desc">{{ !isCrypto ? 'Coins' : 'Money' }} to receive</div>
                      <div class="desc">{{ rightAmount }}</div>
                    </div>
                  </div>
                </div>
                <div class="timer">
                  <div class="title">{{ hours }}:{{ minutes }}:{{ seconds }}</div>
                  <div class="desc">{{ $t(`The price is fixes for 1 hour`) }}</div>
                </div>
              </div>
              </template>
              <template v-else>
                <div class="card-list__item order-success">
                  <div class="order-submit-success">
                    <div class="order-submit-success__title">{{ $t(`Order successfully submitted`) }}</div>
                    <div class="order-submit-success__message">
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';

export default {
  name: 'OrderRequisites',
  props: [],
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data: function() {
    return {
      beneficiary_name: '',
      beneficiary_surname: '',
      iban: '',
      bic: '',
      bank_name: '',
      hours: 1,
      minutes: 0,
      seconds: 0,
      timer: null,
      order: {},
      wallet: '',
      submitSuccess: false
    }
  },
  mounted: async function() {
    await this.getOrderInfo();
    this.startTimer();
  },
  methods: {
    startTimer() {
      this.timer = setInterval(() => {
        if (this.hours === 0 && this.minutes === 0 && this.seconds === 0) {
          clearInterval(this.timer);
        } else {
          if (this.seconds > 0) {
            this.seconds--;
          } else {
            if (this.minutes > 0) {
              this.minutes--;
              this.seconds = 59;
            } else {
              this.hours--;
              this.minutes = 59;
              this.seconds = 59;
            }
          }
        }
      }, 1000);
    },
    async getOrderInfo() {
      const uuid = this.$route.params.uuid;
      const self = this;

      return this.$http.get(process.env.VUE_APP_API + `approved-orders/${uuid}`)
          .then((res) => {
            self.order = res.data.payload.order;
            self.wallet = res.data.payload.wallet;
            self.submitSuccess = self.order.requisites_submitted;
            self.beneficiary_name = self.order.name;
            self.beneficiary_surname = self.order.surname;
          })
          .catch((e) => {
            if (e.response.status === 404) {
              self.$router.push({name: 'Home'}).catch(() => {})
            }
          })
    },
    async submit() {
      const uuid = this.$route.params.uuid;

      await this.$http.put(process.env.VUE_APP_API + `orders/${uuid}/requisites`, {
        beneficiary_name: this.beneficiary_name,
        beneficiary_surname: this.beneficiary_surname,
        iban: this.iban,
        bic: this.bic,
        bank_name: this.bank_name
      }).then((res) => {
        if (res.data.status === 'OK') {
          this.submitSuccess = true;
        }
      }).catch((e) => {
        console.log(e)
      })
    }
  },
  computed: {
    isCrypto() {
      return this.order.payment_type === 'crypto';
    },
    leftAmount()  {
      const order = this.order;

      if (this.isCrypto) {
        return `${order.amount} ${order.source_asset}`;
      } else {
        const currencies = this.$parent.currencies;
        return `${currencies[order['source_asset']]} ${parseFloat(order.amount).toFixed(2)}`
      }
    },
    rightAmount() {
      const order = this.order;
      const currencies = this.$parent.currencies;
      // const parent = this.$parent;

      if (this.isCrypto) {
        // return `${order.dest_amount} ${order.source_asset}`;

        if (currencies && currencies[order['dest_asset']]) {
          return `${currencies[order['dest_asset']].symbol} ${parseFloat(order.dest_amount).toFixed(2)}`
        } else {
          return null;
        }
      } else {
        return `${order.dest_amount} ${order.dest_asset}`;

        // const currencies = this.$parent.currencies;
        // return `${currencies[order['source_asset']]} ${order.amount}`
      }

      // return parent.fiatToCrypto ? `${parent.takeAmount} ${parent.takeCurrency.crypto_currency}` : `${parent.giveCurrency.symbol} ${parent.giveAmount}`;
    },
    fiatCurrency() {
      const parent = this.$parent;
      const asset = this.order[this.isCrypto ? 'dest_asset' : 'source_asset'];
      return parent.currencies && parent.currencies[asset] && parent.currencies[asset].symbol;
    },
    date() {
      return this.$moment(this.order.created_at).format('YYYY-MM-DD');
    }
  },
}
</script>