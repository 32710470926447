<template>
  <main class="main home-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="title big">{{ $t(`Your Ultimate Crypto-Fiat Exchange Platform!`) }}</div>
          <div class="desc">{{ $t(`Experience the simplicity and convenience of DaxChain today, and make your crypto-fiat exchange process smoother than ever before!`) }}</div>
          <div @click="checkAuth" class="button" >{{ $t(`Get Started`) }}</div>
          <ul class="list features">
            <li class="item">
              <img src="./../images/security.svg" class="img"/>
              <div class="title small">{{ $t(`Bank-grade security`) }}</div>
              <div class="desc">{{ $t(`We are more than a bank. We are the full-blown safe bay`) }}</div>
            </li>
            <li class="item">
              <img src="./../images/stopwatch.svg" class="img"/>
              <div class="title small">{{ $t(`Quick KYC`) }}</div>
              <div class="desc">{{ $t(`Our team does everything it can to open a world of possibilities in crypto scop for you`) }}</div>
            </li>
            <li class="item">
              <img src="./../images/customer-service.svg" class="img"/>
              <div class="title small">{{ $t(`Outstanding tech support`) }}</div>
              <div class="desc">{{ $t(`Enjoy the dream tech team service you always wanted`) }}</div>
            </li>
          </ul>
        </div>
      </div>
      <div class="section section-2">
        <div class="wrapper">
          <div class="title big">{{ $t(`What do you want to exchange?`) }}</div>
          <div class="card-list">
            <div class="card-list__item well">
              <div class="list" v-if="exchangeList.length">
                <div class="item" v-for="item in exchangeListUpdated" :key="item.id">
                  <div class="left">
                    <div class="desc">{{item.title}}</div>
                    <div class="desc">{{item.crypto_currency}}</div>
                  </div>
                  <div class="desc">€ {{item.price}}</div>
                  <div class="desc">+ {{item.coeff}}%</div>
                </div>
              </div>
            </div>
            <div :class="{crypto: !fiatToCrypto}" class="card-list__item buy">
              <div class="exchange-container">
                <ExchangeButton @click="fiatToCrypto = !fiatToCrypto" />
              </div>
              <div class="inputs">
                <select class="currency-select" v-if="exchangeList.length" v-model="chosenCrypto"
                        @change="changeTakeAmount(giveAmount)">
                  <option v-for="item in exchangeListUpdated" :key="item.id" :value="item">{{ item.title }}</option>
                </select>
                <div class="input-container">
                  <input type="number" v-model="giveAmount" @input="changeTakeAmount(giveAmount)"/>
                  <div class="desc">EUR</div>
                </div>
              </div>
              <div class="total">
                <div class="desc">{{ $t(`Total:`) }}</div>
                <div class="desc">{{total}}</div>
              </div>
              <button class="button" @click="$parent.toExchange(giveAmount, takeAmount, chosenCrypto)">{{ $t(`Buy Crypto`) }}</button>
            </div>
          </div>
          <div class="widget">
            <div id="tradingview-widget"></div>
          </div>
        </div>
      </div>
      <div class="section section-3">
        <div class="wrapper">
          <div class="top">
            <div class="title big">{{ $t(`FAQ’s`) }}</div>
            <router-link to="faq" class="button">{{ $t(`View all FAQ’s`) }}</router-link>
          </div>
          <div class="accordion">
            <div class="item" v-for="(item, index) in $parent.faq" :key="index">
              <div class="faq-question" @click="toggleAnswer(index)">
                <div :class="['title small', {'active': isActiveAnswer(index)}]">{{ item.question }}</div>
                <img :class="{'rotate-icon': isActiveAnswer(index)}" src="./../images/arrow.svg" class="img"/>
              </div>
              <transition name="fade">
                <div v-html="item.answer" v-if="isActiveAnswer(index)" class="desc faq-answer">
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
      <div class="section section-4">
        <div class="wrapper">
          <ContactForm/>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

import ContactForm from "@/components/ContactForm.vue";
import ExchangeButton from "@/components/ExchangeButton.vue";

export default {
  name: 'Home',
  props: [],
  components: {
    ExchangeButton,
    ContactForm
  },
  data: function() {
    return {
      activePanels: [],
      exchangeList: [],
      giveAmount: 100,
      takeAmount: 0,
      chosenCrypto: {},
      fiatToCrypto: true
    }
  },
  computed: {
    exchangeListUpdated() {
      return this.exchangeList.filter(item => item.currency === 'EUR');
    },
    total() {
      if (this.chosenCrypto?.price) {
        if (this.fiatToCrypto) {
          return `${this.giveAmount / this.chosenCrypto.price} ${this.chosenCrypto.crypto_currency.toUpperCase()}`;
        }

        return `${this.chosenCrypto.price * this.giveAmount} EUR`;
      }

      return 0;
    }
  },
  mounted() {
    if (this.$route.query['redirect']) {
      this.$parent.openSignInModal();
    }

    this.getExchangeList();
    const script = document.createElement("script");
    script.src = "https://s3.tradingview.com/tv.js";
    script.async = true;
    script.onload = this.initializeWidget;
    document.body.appendChild(script);
  },
  methods: {
    checkAuth() {
      if (this.$parent.isAuth) {
        this.$router.push({path: '/buy-crypto'});
      } else {
        this.$parent.openSignInModal();
      }
    },
    getExchangeList() {
      this.$http.get(process.env.VUE_APP_API + 'exchange-top')
      .then((res) => {
        this.exchangeList = res.data.payload;
        this.chosenCrypto = res.data.payload[0]
        this.changeTakeAmount(this.giveAmount);
      })
      .catch(() => {
         
      })
    },
   changeTakeAmount(newValue) {
    if (this.chosenCrypto.price) {
      this.takeAmount = newValue / this.chosenCrypto.price;
    } else {
      this.takeAmount = 0;
    }
   },
    toggleAnswer(index) {
      if (this.isActiveAnswer(index)) {
        this.activePanels.splice(this.activePanels.indexOf(index), 1)
      } else {
        this.activePanels.push(index)
      }
    },
    isActiveAnswer(index) {
      return this.activePanels.includes(index)
    },
    initializeWidget() {
      new window.TradingView.widget({
        autosize: true,
        symbol: "BITSTAMP:BTCUSD",
        interval: "D",
        timezone: "Etc/UTC",
        theme: "light",
        style: "1",
        locale: "en",
        gridColor: "#F1F3F8",
        toolbar_bg: "#E1E7F1",
        enable_publishing: false,
        allow_symbol_change: true,
        container_id: "tradingview-widget",
      });
    }
  }
}
</script>
