<template>
	<header class="header">
		<div class="wrapper">
			<div class="left">
				<router-link to="/" class="logo">
					<img src="./../images/logo.svg" class="img" />
				</router-link>
			</div>
			<div class="right">
				<ul class="nav">
					<Dropdown v-if="allLanguages.length > 0" :options="allLanguages" value-key="id" label-key="name"
						placeholder="Language" :default-value="currentLanguage" container-class="language-dropdown"
						@select="selectLanguage" />
					<template v-for="item in $parent.headerNav">
						<li class="nav__item" :key="item.title">
							<router-link class="desc" :to="item.path">
								{{ $t(item.title) }}
							</router-link>
						</li>
					</template>
				</ul>
				<div v-if="!$parent.isAuth" class="user-link hover-link" @click="$parent.openSignInModal">
					<div class="user">
						<img src="./../images/user.svg" class="img" />
					</div>
				</div>
				<router-link v-if="$parent.isAuth" class="user-link hover-link" to="/profile">
					<div class="user">
						<img src="./../images/user.svg" class="img" />
					</div>
				</router-link>
			</div>
		</div>
	</header>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
	name: 'Header',
	props: [],
	components: {
	},
	computed: {
		...mapGetters("app", ['currentLanguage', 'allLanguages']),
	},
	data: function () {
		return {
		}
	},
	methods: {
		selectLanguage(lang) {
			this.changeLocale(lang.id);
		},

		...mapActions('app', ['changeLocale']),

	}
}
</script>

<style lang="scss">
.header .wrapper .select-container.language-dropdown {
	width: 75px;
	cursor: pointer;
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	line-height: 19px;
	color: #282E38;
	font-weight: 700;

	&:focus {
		border: none;
	}

	.dropdown-selected {
		padding: 15px;
	}

	.dropdown-selected:hover,
	.dropdown-item:hover {
		opacity: .7;
		background: initial;
	}
}
</style>
